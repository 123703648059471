import { jsx } from "react/jsx-runtime";
import { createComponentStore } from "@tecnobit-srl/components-store";
import { Spinner } from "@tecnobit-srl/ui-spinner";
const spinnerContainerStyle = {
  width: "100%",
  height: "100%",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};
const {
  Component: InnerRoute,
  ComponentStoreProvider: RouteTypesProvider,
  useAddComponent: useAddRouteType
} = createComponentStore(
  "route-types",
  /* @__PURE__ */ jsx("div", { style: spinnerContainerStyle, children: /* @__PURE__ */ jsx(Spinner, {}) })
);
function Route(props) {
  return /* @__PURE__ */ jsx(
    InnerRoute,
    {
      id: props.type,
      p: {
        route: props
      }
    }
  );
}
export {
  Route,
  RouteTypesProvider,
  useAddRouteType
};
